import { Link } from "react-router-dom";

function NotFound() {
    return ( 
        <div className=" min-h-screen flex justify-center items-center">
            <div className="text-center">
                <p className="text-4xl">404</p>
                <p className="text-gray-400 text-lg">Page not found</p>

                <Link to="/"><span className="mt-6 block text-blue-500">Go Home</span></Link>
            </div>
            
        </div>
     );
}

export default NotFound;