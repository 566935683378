
function Footer() {
    return ( 
        <div className="bg-[#EEE]">
            <div className="container mx-auto px-4 py-8">
                <p>{new Date().getFullYear()} &copy; All rights reserved - Electrovolt</p>
                {/* <div className="flex my-8">
                    <div className=" max-w-xs text-left p-4 ">
                        <p className="text-[#333] text-xl font-semibold">For business enquiries please contact</p>
                    </div>
                    <div className=" grow text-left p-4 grid grid-cols-2">
                        <div className="col-span-2 md:col-span-1 flex flex-col">
                            <a href="mailto:hello@electrovolt.io" className="text-[#333]  font-semibold">hello@electrovolt.io</a>
                        </div>
                        <div className="col-span-2 md:col-span-1 flex flex-col">
                            <p className="text-[#333] font-semibold">Links</p>
                            <a href="" className="text-[#333] mt-4">Twitter</a>
                            <a href="" className="text-[#333] mt-2">Github</a>
                            <a href="" className="text-[#333] mt-2">Blog</a>

                        </div>
                       
                    </div>
                </div> */}
            </div>
        </div>
     );
}

export default Footer;